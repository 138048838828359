import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Auth from '@aws-amplify/auth';
import { jwtDecode } from 'jwt-decode';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-cognito-callback',
  standalone: true,
  imports: [],
  template: '',
})
export class CognitoCallbackComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params) => {
      const authorizationCode = params['code'];
      const state = params['state'];

      if (!authorizationCode) {
        this.router.navigate(['/login-error']);
      }

      const token = await this.exchangeCodeForToken(authorizationCode);
      const { id_token, expires_in, access_token, refresh_token } = token;
      const jwtDecoded: any = jwtDecode(id_token);

      await Auth.federatedSignIn(
        `cognito-idp.${environment.region}.amazonaws.com/${environment.amplifyConfig.Auth.userPoolId}`,
        {
          token: id_token,
          expires_at: Math.floor(Date.now() / 1000) + expires_in,
        },
        {
          name: jwtDecoded.name,
          email: jwtDecoded.email,
          picture: jwtDecoded.picture,
          provider: 'cognito',
        } as any
      );

      this.authService.setAmplifyV5PlusCookies({
        idToken: id_token,
        accessToken: access_token,
        refreshToken: refresh_token,
      });

      this.authService.setCognitoHostedUILoginFlag();

      if (state) {
        window.location.href = state;
      } else {
        window.location.href = '/';
      }
    });
  }

  async exchangeCodeForToken(code: string): Promise<any> {
    const clientId = environment.amplifyConfig.Auth.userPoolWebClientId;
    const redirectUri = environment.cognito.redirectUri;
    const tokenUrl = environment.cognito.tokenUrl;

    const params = new HttpParams()
      .set('code', code)
      .set('client_id', clientId)
      .set('redirect_uri', redirectUri)
      .set('grant_type', 'authorization_code');

    return await firstValueFrom(this.http.post(tokenUrl, params));
  }
}
