import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

import { environment } from 'src/environments/environment';

import { GlobalErrorHandler } from './global-error-handler';

import { SystemService } from './system.service';
import { AuthService } from './auth/auth.service';

import { initializeKeycloak } from './auth/keycloak-init';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule, KeycloakAngularModule],
  providers: [
    { provide: 'googleTagManagerId', useValue: environment.gtmIdentification },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    SystemService,
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: (service: SystemService) => () => service.onApplicationLoad(),
      deps: [SystemService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AuthService],
    },
  ],
  exports: [HttpClientModule],
})
export class CoreModule {}
